var fuse_dynamic = undefined, quick_search_dynamic = undefined;
var fuse_static = undefined, quick_search_static = undefined;
var threshold = 0.2;

function loadQuickSearchActions() {
    var url = window.url__profiles__quick_search_actions;
    if (!url) return;
    $.get(url, function(json) {
        quick_search_dynamic = json.data
        fuse_dynamic = new Fuse(quick_search_dynamic, {
            keys: ['label', 'aliases'],
            threshold: threshold
        });
    });
}

function loadQuickSearchStatic() {
    var url = window.url__profiles__quick_search_static;
    if (!url) return;
    $.get(url, function(json) {
        quick_search_static = json.data
        fuse_static = new Fuse(quick_search_static, {
            keys: ['aliases'],
            threshold: threshold,
        });
    });
}

function setupQuickSearch() {

    function get_input_badge(input) {
        return $('.quick-search-input-badge', input.closest('.input-group'));
    }

    function get_bootstrap_autocomplete(input) {
        return $('.bootstrap-autocomplete', input.closest('.input-group'));
    }

    $('.quick-search').each(function() {
        var qs = $(this);

        function on_activate() {
            let items = get_bootstrap_autocomplete(qs).find('a');
            items.first().addClass('active');
            items.prop('href', '#');
        }

        qs.autoComplete({
            minLength: 0,
            resolver: 'custom',
            formatResult: function (item) {
                var label = '', details = '';
                if (item.type === 'static' || item.type === 'action') {
                    label += '<div>' + item.label + '</div>';
                } else if (item.type === 'command') {
                    label += '<div><i class="me-2 fas fa-arrow-right"></i>' + item.label + '</div>';
                } else {
                    var icon = '';
                    if (item.type === 'property') {
                        icon = 'fa-home';
                    } else if (item.type === 'landlord') {
                        icon = 'fa-user';
                    } else if (item.type === 'tenant') {
                        icon = 'fa-house-user';
                    } else if (item.type === 'ticket') {
                        icon = 'fa-tasks';
                    } else if (item.type === 'supplier') {
                        icon = ' fa-store';
                    } else if (item.type === 'key') {
                        icon = ' fa-key';
                    }
                    label += '<div><i class="me-2 fas ' + icon + '"></i>' + item.label + '</div>';
                    details += '<div style="line-height: normal">';
                    details += '<div class="small text-muted">' + item.sub_label + '</div>';
                    if (item.sub_label2) {
                        details += '<div class="small text-muted">' + item.sub_label2 + '</div>';
                    }
                    details += '</div>';
                }
                return {
                    text: '',
                    html: [
                        label,
                        details,
                    ]
                };
            },
            events: {
                search: function (qry, callback, input) {
                    var input_badge = get_input_badge(input);
                    let matches = [];
                    qry = qry.trim();
                    let empty_qry = !qry || /^\s*$/.test(qry);
                    if (!input_badge.length && empty_qry) {
                        if (quick_search_static !== undefined) {
                            matches = quick_search_static.filter(function (item) {
                                return item.default === true;
                            });
                        }
                    } else {
                        matches = [];
                        if (!input_badge.length) {
                            if (fuse_dynamic !== undefined) {
                                matches.push(...fuse_dynamic.search(qry).map(function (match) {
                                    return match.item;
                                }));
                            }
                            if (fuse_static !== undefined) {
                                matches.push(...fuse_static.search(qry).map(function (match) {
                                    return match.item;
                                }));
                            }
                        } else {
                            let item = input_badge.data('item');
                            if (item.type !== 'command') {
                                matches = item.actions;
                            } else {
                                for (let k in quick_search_dynamic) {
                                    if (quick_search_dynamic[k].type === item.action.type) {
                                        for (let a in quick_search_dynamic[k].actions) {
                                            if (quick_search_dynamic[k].actions[a].label === item.action.label) {
                                                let action = $.extend({}, quick_search_dynamic[k].actions[a]);
                                                action.label = quick_search_dynamic[k].label;
                                                action.sub_label = quick_search_dynamic[k].sub_label;
                                                action.sub_label2 = quick_search_dynamic[k].sub_label2;
                                                action.type = quick_search_dynamic[k].type;
                                                action.is_action = true;
                                                matches.push(action);
                                            }
                                        }
                                    }
                                }
                            }
                            if (!empty_qry) {
                                let fuse_actions = new Fuse(matches, {
                                    keys: ['label'],
                                    threshold: threshold,
                                });
                                matches = fuse_actions.search(qry).map(function (match) {
                                    return match.item;
                                });
                            }
                        }
                        if (!empty_qry) {
                            logSearchTimer(qry, matches.length);
                        }
                    }

                    if (empty_qry && matches.length === 1) {
                        let item = matches[0];
                        if (item.type === 'action' || item.is_action) {
                            window.location = item.url;
                            return;
                        }
                    }

                    callback(matches);
                    on_activate();
                },
            }
        }).click(function () {
            var input = $(this);
            input.autoComplete('show');
        }).on('autocomplete.select', function (evt, item) {
            if (item.type === 'static') {
                window.location = item.target;
            } else if (item.type === 'action' || item.is_action) {
                window.location = item.url;
            } else {
                var input = $(this);
                get_input_badge(input).remove();
                var html = $('<span class="quick-search-input-badge input-group-text"><span class="badge rounded-pill text-bg-primary">' + item.label + '</span></span>');
                html.data('item', item);
                input.before(html);
                if (!input.hasClass('mob-qs')) {
                    get_bootstrap_autocomplete(input).css('left', input.position().left);
                }
                setTimeout(
                    function () {
                        input.focus();
                        input.autoComplete('show');
                    }, 250);
            }
        }).keydown(function (e) {
            var input = $(this);
            if (e.key === 'Backspace' || e.key === 'Delete') {
                if (!input.val()) {
                    get_input_badge(input).remove();
                    if (!input.hasClass('mob-qs')) {
                        get_bootstrap_autocomplete(input).css('left', input.position().left);
                    }
                }
            }
        }).on('autocomplete.dd.shown', on_activate);
    });

    document.addEventListener("keyup", e => {
        if (!e.key || e.key.toLowerCase() !== "p" || !e.altKey) return;
        e.preventDefault();
        $('.quick-search:visible').focus();
    });

    var logTimer;
    function logSearchTimer(qry, count) {
        if (logTimer) {
            clearTimeout(logTimer);
        }
        logTimer = setTimeout(logSearch, 500, qry, count);
    }
    function logSearch(qry, count) {
        var url = window.url__profiles__quick_search_log;
        var data = {
            'q': qry,
            'c': count,
            'csrfmiddlewaretoken': window.csrf_token,
        };
        $.post(url, data);
    }
}

$(document).ready(function() {
    loadQuickSearchStatic();
    loadQuickSearchActions();
    setupQuickSearch();
});
