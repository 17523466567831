
function setupTabAnchors(tab_selector, store_in_cookie=false, switch_url_anchor=true) {
    var tabs = $(tab_selector + ' a');

    // store in cookie current tab
    if (store_in_cookie) {
        tabs.on('shown.bs.tab', function (e) {
            Cookies.set('tab_hash', e.target.hash, { sameSite: 'Strict', secure: true });
        })
    }

    // show tab chosen by anchor in URL
    if (switch_url_anchor) {
        var hash = location.hash.replace(/^#/, '');
        if (hash && hash.startsWith('nav-')) {
            $(tab_selector + ' a[href="#' + hash + '"]').tab('show');
        }
    }

    // re-draw charts when tabs change
    tabs.on('shown.bs.tab', function (e) {
        var tab = $(e.target.hash);
        // old code for c3 charts
        tab.find('.c3').each(function() {
            var chart = $(this).data('c3-chart');
            chart.flush();
        });
        let map = tab.data('map');
        if (map) {
            map.invalidateSize();
        }
        let maps = tab.data('maps');
        if (maps) {
            for (let i in maps) {
                maps[i].invalidateSize();
            }
        }
    });

    // store in history when a tab is clicked
    tabs.on('click', function(e) {
        let href = $(this).attr('href');
        if (href.indexOf('#nav-') !== -1) {
            history.pushState(null, null, href);
        }
    });

    // show hash when navigation changes
    window.addEventListener("popstate", function(e) {
        var hash = location.hash.replace(/^#/, '');
        if (hash && hash.startsWith('nav-')) {
            $(tab_selector + ' a[href="#' + hash + '"]').tab('show');
        } else {
            $(tab_selector + ' a:first').tab('show');
        }
    });
}
