window.clear_form_errors = function(form) {
    $('.form-control', form).prop('title', '');
    var form_groups = form.find('div.row');
    form_groups.find('.invalid-feedback').remove();
    form_groups.find('input').removeClass('is-invalid');
    $('.dynamic-alert-error').remove();
};

window.show_form_errors = function(parent, errors, help_block) {
    for (var k in errors) {
        if (k === '__all__' && help_block !== undefined) {
            parent.prepend('<div class="alert alert-danger dynamic-alert-error" role="alert">' + errors[k] + '</span>');
        } else {
            var control = $('#id_'+ k, parent);
            control.addClass('is-invalid');
            control.prop('title', errors[k]);
            if (help_block !== undefined) {
                control.parent().append('<strong class="invalid-feedback">' + errors[k] + '</strong>');
            }
        }
    }
};

window.setup_dynamic_form = function(form, populated_callback, missing_callback, submit_callback) {
    var formTimer;
    function runUpdate() {
        var missing = [];
        form.find('input').each(function () {
            var input = $(this);
            if (!input.val() && input.attr('required')) {
                missing.push(input);
            }
        });
        if (missing.length) {
            missing_callback(missing);
            return false;
        }
        else {
            populated_callback();
            return true;
        }
    }
    function formTimerUpdate() {
        if(formTimer) {
            clearTimeout(formTimer);
        }
        formTimer = setTimeout(runUpdate, 1000);
    }
    function formSubmitUpdate() {
        if(runUpdate()) {
            submit_callback();
            return false;
        }
        else {
            return true;
        }
    }
    form.find('input').on('input propertychange paste', formTimerUpdate);
    form.find('select').on('change', formTimerUpdate);
    form.on('submit', formSubmitUpdate);
};

$.fn.serializeObject = function() {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function() {
        if (o[this.name]) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};
