
function apex_bar_chart(selector, options, money=true) {
    options = $.extend(true, {
        chart: {
            type: 'bar',
            height: 300,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '100%',
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['transparent']
        },
        fill: {
            opacity: 1
        },
        xaxis: {
            labels: {
                rotate: 0,
            },
        }
    }, options);
    if (money) {
        options = $.extend(true, {
            yaxis: {
                labels: {
                    formatter: function (val) {
                        return "£" + Number(val).toLocaleString()
                    }
                }
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "£" + Number(val).toLocaleString()
                    }
                }
            }
        }, options);
    }
    return apex_render(selector, options);
}

function _apex_donut_pie_chart(selector, options) {
    options = $.extend(true, {
        stroke: {
            width: 1,
        },
        legend: {
            position: 'bottom',
        },
        title: {
            align: 'center',
            margin: 5,
            style: {
                fontWeight: 'normal',
                fontSize: '12px',
            }
        },
    }, options);
    return apex_render(selector, options);
}

function apex_donut_chart(selector, options) {
    return _apex_donut_pie_chart(selector, $.extend(true, {
        chart: {
            type: 'donut',
            height: 300,
        }
    }, options));
}

function apex_pie_chart(selector, options) {
    return _apex_donut_pie_chart(selector, $.extend(true, {
        chart: {
            type: 'pie',
            height: 500,
        }
    }, options));
}

function apex_line_chart(selector, options) {
    options = $.extend(true, {
        chart: {
            type: 'line',
            zoom: {
                type: 'x',
                enabled: true,
            },
            height: 500,
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
        },
        stroke: {
            curve: 'smooth',
            width: 1,
        },
    }, options);
    return apex_render(selector, options);
}

function apex_boxplot_chart(selector, options) {
    options = $.extend(true, {
        chart: {
            type: 'boxPlot',
            height: 180,
            zoom: {
                enabled: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        colors: ['#008FFB', '#FEB019'],
        yaxis: {
            labels: {
                formatter: function (val) {
                    return "£" + Number(val / 1000).toLocaleString() + "k";
                }
            },
        },
        tooltip: {
            shared: false,
            intersect: true,
        },
    }, options);
    return apex_render(selector, options);
}

function apex_render(selector, options) {
    var chart = new ApexCharts(document.querySelector(selector), options);
    chart.render();
    return chart;
}
